<template>
  <v-dialog v-model="showModal" scrollable width="1000px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 600px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="`${currentSuggestion.id!=null ? 'Update Suggestion':'Add Suggestion'}`" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <v-col cols="12" sm="3" md="3">
              <label for="">Suggestion Name</label>
              <v-text-field
                  v-model="currentSuggestion.name"
                  outlined
                  required
                  :rules="[(v) => !!v || 'Suggestion name is required']"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ></v-text-field>
            </v-col>
          </div>

          <v-row v-for="(product,ind) in currentSuggestion.products" :key="ind" class="py-4 px-2 my-0">
            <v-col md="3">
              <label for="">Venue </label>
              <v-select
                  v-model="product.venueId"
                  :items="venues"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Venue is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProductTypes(ind)"
              >
              </v-select>
            </v-col>
            <v-col md="2">
              <label for="">Product Type </label>
              <v-select
                  v-model="product.productType"
                  :items="product.productTypes"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Product Type is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="loadTypeData(ind)"
              >
              </v-select>
            </v-col>
            <v-col md="3" v-if="getName(ind) !== 'POS'">
              <label for="">{{ getName(ind) }}</label>
              <v-select
                  v-model="product.event_id"
                  v-if="getName(ind) == 'Event'"
                  :items="product.events"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Event is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.trainer_id"
                  v-else-if="getName(ind) == 'Trainer'"
                  :items="product.trainers"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Trainer is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.membership_id"
                  v-else-if="getName(ind) == 'Membership'"
                  :items="product.memberships"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Membership is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.facility_id"
                  v-else-if="getName(ind) == 'Facility'"
                  :items="product.facilities"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Facility is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.workshop_id"
                  v-else-if="getName(ind) == 'Academy'"
                  :items="product.academies"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Academy is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
              <v-select
                  v-model="product.academy_id"
                  v-else
                  :items="[]"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Choose product type']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  @change="getProducts(ind)"
              >
              </v-select>
            </v-col>
            <v-col md="3">
              <label for="">Product </label>
              <v-select
                  v-model="product.product_id"
                  :items="product.products"
                  item-text="name"
                  item-value="id"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Product is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
              >
              </v-select>
            </v-col>
            <v-col md="1" class="d-flex justify-center align-center" v-if="currentSuggestion.products.length > 1 && ind !== 0">
              <v-btn
                  color="white"
                  class="small mt-4"
                  depressed
                  @click="deleteProduct(ind)"
              >
                <v-icon color="#F44336">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>


            <div class=" w-full benefit-bar py-4 px-2 ml-2" v-if="ind === 0">
              <p class="mb-0">Suggestions</p>
            </div>

          </v-row>

          <v-col>
            <v-btn
                class="teal-color"
                @click="addNewPackage"
                dark
                elevation="0"
                style="font-weight: 600"
            >
              <v-icon left dark>mdi-plus</v-icon>
              Add More Products
            </v-btn>
          </v-col>

        </v-card-text>

        <v-card-actions class="card-footer">
          <div class="d-flex justify-end ml-4" style="width: 100%">
            <div>
              <v-btn
                  class="ma-2 "
                  text
                  @click="close"
              >Close
              </v-btn
              >

              <v-btn
                  elevation="0"
                  class="ma-2 white--text blue-color" @click="saveProduct"
              >{{ currentSuggestion.id != null ? "Update" : "Save" }}
              </v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>

</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "PackageModal",
  components: {SvgIcon},
  watch: {
    editSuggestions(val){
      if (val !== null) {
        let prod = val.suggestion_products
        this.currentSuggestion = {
          id:val.id,
          name: val.suggestion_name,
          products: prod,
        }


        console.log('current suggestion', this.currentSuggestion)

        this.currentSuggestion.products.forEach(async (p,ind) => {
          await this.getProductTypes(ind)
          // await this.loadTypeData(ind)
          // await this.getProducts(ind)
        })

      }
    },
    refreshForm(){
      this.currentSuggestion = {
          id: null,
          name: null,
          products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
        }],
      }
    },
    // id(val) {
    //   if (val != null) {
    //     this.getPackageDetails();
    //   } else {
    //     this.currentSuggestion = {
    //       id: null,
    //       name: null,
    //       products: [{
    //         venueId: null,
    //         productType: null,
    //         product_id: null,
    //         name: null,
    //         price: null,
    //         event_id: null,
    //         trainer_id: null,
    //         membership_id: null,
    //         facility_id: null,
    //         academy_id: null,
    //       }],
    //       offerPrice: 0,
    //     };
    //   }
    // },
  },
  props: {
    refreshForm:{type: Boolean, default: false},
    showModal: {type: Boolean, default: false},
    id: {type: Number, default: null},
    editSuggestions: {type: Object, default: null},
  },
  computed: {
    venues() {
      return this.$store.getters.userVenues;
    },
  },
  data() {
    return {
      deleted_suggestion_product_id:[],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      currentSuggestion: {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
          is_main:1,
        }],
      },
      valid: false,
    }
  },
  methods: {
    async getProductTypes(index){
      this.currentSuggestion.products[index].productTypes = [];
      this.currentSuggestion.products[index].facilities = [];
      this.currentSuggestion.products[index].events = [];
      this.currentSuggestion.products[index].trainers = [];
      this.currentSuggestion.products[index].memberships = [];
      this.currentSuggestion.products[index].academies = [];
      this.currentSuggestion.products[index].products = [];
      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/suggestions/get/product-types/${this.currentSuggestion.products[index].venueId}`)
          .then(async (response) => {
            if (response.status == 200 && response.data.status) {
              let permissions = response.data.data.permissions;

              this.currentSuggestion.products[index].productTypes = Object.values(permissions)
                  .filter(permission => permission.allowed === 1)
                  .map(permission => ({
                    id: permission.id,
                    name: permission.name
                  }));
              if(this.currentSuggestion.products[index].productType){
                await this.loadTypeData(index);
              }
              this.$forceUpdate();
            }else{
              this.currentSuggestion.products[index].productTypes = [];
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },
    async loadTypeData(index){
      if(this.currentSuggestion.products[index].productType === 9){
        return this.getProducts(index);
      }
      this.currentSuggestion.products[index].facilities = [];
      this.currentSuggestion.products[index].events = [];
      this.currentSuggestion.products[index].trainers = [];
      this.currentSuggestion.products[index].memberships = [];
      this.currentSuggestion.products[index].academies = [];
      this.currentSuggestion.products[index].products = [];

      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/suggestions/get/type-data/${this.currentSuggestion.products[index].venueId}/${this.currentSuggestion.products[index].productType}`)
          .then(async (response) => {
            if (response.status == 200 && response.data.status) {
              this.currentSuggestion.products[index].facilities = response.data.data.facilities;
              this.currentSuggestion.products[index].events = response.data.data.events;
              this.currentSuggestion.products[index].trainers = response.data.data.trainers;
              this.currentSuggestion.products[index].memberships = response.data.data.memberships;
              this.currentSuggestion.products[index].academies = response.data.data.academies;


              let filter = this.currentSuggestion.products[index].productTypes.find(i => i.id === this.currentSuggestion.products[index].productType);
              let obj = this.currentSuggestion.products[index];
              let typeId = 0;

              if(filter.name === 'Event'){
                typeId = obj.event_id
              }
              if(filter.name === 'Trainer'){
                typeId = obj.trainer_id
              }
              if(filter.name === 'Membership'){
                typeId = obj.membership_id
              }
              if(filter.name === 'Facility'){
                typeId = obj.facility_id
              }
              if(filter.name === 'Academy'){
                typeId = obj.workshop_id
              }

              if(typeId){
                await this.getProducts(index)
              }
              this.$forceUpdate()
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },
    async getProducts(index){
      this.currentSuggestion.products[index].products = [];
      let filter = this.currentSuggestion.products[index].productTypes.find(i => i.id === this.currentSuggestion.products[index].productType);
      let obj = this.currentSuggestion.products[index];
      let typeId = 0;

      if(filter.name === 'Event'){
        typeId = obj.event_id
      }
      if(filter.name === 'Trainer'){
        typeId = obj.trainer_id
      }
      if(filter.name === 'Membership'){
        typeId = obj.membership_id
      }
      if(filter.name === 'Facility'){
        typeId = obj.facility_id
      }
      if(filter.name === 'Academy'){
        typeId = obj.workshop_id
      }

      this.showLoader("Loading products");
      await this.$http.get(`venues/offers/suggestions/get/products/${this.currentSuggestion.products[index].venueId}/${this.currentSuggestion.products[index].productType}/${typeId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.currentSuggestion.products[index].products = response.data.data;
            }else{
              this.currentSuggestion.products[index].products = [];
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.$forceUpdate()
            this.hideLoader()
          })
    },

    addNewPackage() {
      this.currentSuggestion.products.push({
        productTypes:[],
        products:[],
        events:[],
        trainers:[],
        memberships:[],
        facilities:[],
        academies:[],
        venueId: null,
        productType: null,
        product_id: null,
        name: null,
        event_id: null,
        trainer_id: null,
        membership_id: null,
        facility_id: null,
        academy_id: null,
      })
    },
    getName(index) {
      let name = 'Type';
      if (this.currentSuggestion.products[index].productType) {
        let find = this.currentSuggestion.products[index].productTypes.find(e => e.id === this.currentSuggestion.products[index].productType);
        if (find) {
          return find.name;
        }
      }
      return name;
    },
    getPackageDetails() {
      console.log('fetching');
    },
    confirmActions(data) {
      console.log(data);
    },
    close() {
      this.currentSuggestion = {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
        }],
      }
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    deleteProduct(i){
      if(this.currentSuggestion.products[i].suggestion_product_id){
        this.deleted_suggestion_product_id.push(this.currentSuggestion.products[i].suggestion_product_id);
      }
      this.currentSuggestion.products.splice(i, 1);
    },
    saveProduct() {

      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.currentSuggestion));

      data.products.map((ele,ind) => {
        ele.is_main = ind === 0;
        delete ele.academies;
        delete ele.facilities;
        delete ele.trainers;
        delete ele.events;
        delete ele.products;
        delete ele.memberships;
        delete ele.productTypes;
        delete ele.hover;
      });

      if(this.deleted_suggestion_product_id.length > 0){
        data.deleted_suggestion_product_id = this.deleted_suggestion_product_id;
      }

      this.$emit('save', data);
      this.currentSuggestion = {
        id: null,
        name: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
        }],
      };
      this.deleted_package_product_id = [];
      this.$refs.form.resetValidation();
    }
  }
}
</script>
<style lang="scss">
.benefit-bar {
  background: rgba(79, 174, 175, 0.05);
  color: rgba(79, 174, 175, 1);
  font-weight: 600;
}

.card-rounded-bottom {
  border-radius: 10px !important;
}

.card-footer {
  background: rgba(248, 250, 251, 1);
}

.text_before_price {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}

.text_after_price {
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;

}
</style>